import { Button, Modal, Select, message } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAnInstantQuoteByApi } from '@/api';
import banner2CartImg from '@/assets/images/home/banner2cart.png';
import banner2MapImg from '@/assets/images/home/newMap.png';
import { formatGMapsAddress, getDeDuplicationData } from '@/common';
import GetQuotes from '@/components/GetQuotes';
import GetQuotesSuccess from '@/components/GetQuotesSuccess';
import GoogleMaps from '@/components/GoogleMaps';
import {
  GET_QUOTES_MONTHLY_VOLUME_1_VAL,
  GET_QUOTES_SUCCESS_TYPE_1_KEY,
  GET_QUOTES_SUCCESS_TYPE_2_KEY
} from '@/constants';
import { getAnInstantQuoteSuccessAction } from '@/redux/actions';
import { useTranslation } from 'react-i18next';
import './index.scss';
import './mobile.scss';

const defaultProps = {
  isShow: false,
  onModalCancel: () => {
  },
};

const PORT_AND_RAIL_VAL = 'Port and Rail';
const PORT_VAL = 'Port';
const RAIL_VAL = 'Rail';

export default function GetAnInstantCodePortRailDrayage(props) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch();
  const { t,i18n } = useTranslation();
  const transportTypeList = [
    // {
    //   label: 'Port and Rail',
    //   value: PORT_AND_RAIL_VAL
    // },
    {
      label: t('instant_dra_port'),
      value: PORT_VAL
    },
    // {
    //   label: t('instant_dra_rail'),
    //   value: RAIL_VAL
    // }
  ]

  const pickUpLocationPortAndRailList = [
    {
      label: t('instant_dra_los'),
      value: 'address1',
      isShowBoxShadow: true,
    },
    {
      label: t('instant_dra_sava'),
      value: 'address2',
      isShowBoxShadow: true,
    },
    {
      label: t('instant_dra_nj'),
      value: 'address4',
      isShowBoxShadow: true,
    },
    {
      label: t('instant_dra_hou'),
      value: 'address3',
      isShowBoxShadow: true,
    },
    {
      label: t('instant_dra_sea'),
      value: 'address5',
      isShowBoxShadow: true,
    },
    {
      label: t('instant_dra_tacoma'),
      value: 'address6',
      isShowBoxShadow: true,
    }
  ]
  const pickUpLocationPortList = [
    ...pickUpLocationPortAndRailList,
    {
      label: t('instant_dra_norfolk'),
      value: 'address7'
    },
    {
      label: t('instant_dra_baltim'),
      value: 'address8'
    },
    {
      label: t('instant_dra_char'),
      value: 'address9'
    },
    {
      label: t('instant_dra_tampa'),
      value: 'address10'
    },
    {
      label: t('instant_dra_jack'),
      value: 'address11'
    },
    {
      label: t('instant_dra_miami'),
      value: 'address12'
    },
    {
      label: t('instant_dra_mobile'),
      value: 'address13'
    },
    {
      label: t('instant_dra_boston'),
      value: 'address14'
    },
    {
      label: t('instant_dra_oakland'),
      value: 'address15'
    },
    {
      label: t('instant_dra_vancouver'),
      value: 'address16'
    },
    {
      label: t('instant_dra_toronto'),
      value: 'address17'
    }
  ]
  const pickUpLocationRailList = [
    ...pickUpLocationPortAndRailList,
    {
      label: t('instant_dra_chicago'),
      value: 'address18'
    },
    {
      label: t('instant_dra_dallas'),
      value: 'address19'
    },
    {
      label: t('instant_dra_atlanta'),
      value: 'address20'
    },
    {
      label: t('instant_dra_kansas'),
      value: 'address21'
    }
  ]
  const defaultCurrentTransportType = transportTypeList[0].value

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'get-an-instant-code-port-rail-drayage-mobile-modal' : 'get-an-instant-code-port-rail-drayage-modal'
  const anInstantQuoteState = useSelector(state => state.anInstantQuote)
  const anInstantQuoteData = anInstantQuoteState.data

  const [currentTransportType, setCurrentTransportType] = useState(defaultCurrentTransportType)
  const handleTransportTypeItemClick = (item, index) => {
    const newVal = item.value
    if (currentTransportType === newVal) return
    setCurrentTransportType(newVal)
  }

  const usingPickUpLocationList = useMemo(() => {
    switch (currentTransportType) {
      case PORT_VAL:
        return pickUpLocationPortList
      case RAIL_VAL:
        return pickUpLocationRailList
      default:
        return pickUpLocationPortAndRailList
    }
  }, [currentTransportType, i18n.language])
  const [currentPickUpLocation, setCurrentPickUpLocation] = useState('')
  useEffect(() => {
    setCurrentPickUpLocation(usingPickUpLocationList[0].value)
  }, [usingPickUpLocationList])
  const handlePickUpLocationClick = (item, index) => {
    const newVal = item.value
    if (currentPickUpLocation === newVal) return
    setCurrentPickUpLocation(newVal)
  }

  const renderTransportType = () => {
    const defaultClassNameList = ['get-an-instant-code-port-rail-drayage-transport-type-item-container']
    const activeClassNameList = [...defaultClassNameList, 'get-an-instant-code-port-rail-drayage-transport-type-active-item-container']
    return (
      <div className={'get-an-instant-code-port-rail-drayage-transport-type-list-container'}>
        {
          transportTypeList
            .map((item, index) => {
              const usingClassNameList = item.value === currentTransportType ? activeClassNameList : defaultClassNameList
              const className = usingClassNameList.join(' ')
              return (
                <Button
                  className={className}
                  onClick={() => handleTransportTypeItemClick(item, index)}
                  key={'transport_type_' + index}>
                  {item.label}
                </Button>
              )
            })
        }
      </div>
    )
  }
  const renderPickUpLocation = () => {
    const defaultClassNameList = ['get-an-instant-code-port-rail-drayage-pick-up-location-item-container']
    const activeClassNameList = [...defaultClassNameList, 'get-an-instant-code-port-rail-drayage-pick-up-location-active-item-container']
    return (
      <div className={'get-an-instant-code-port-rail-drayage-pick-up-location-list-container'}>
        {
          usingPickUpLocationList
            .map((item, index) => {
              const usingClassNameList = item.value === currentPickUpLocation ?
                activeClassNameList :
                item.isShowBoxShadow ?
                  [...defaultClassNameList, 'get-an-instant-code-port-rail-drayage-pick-up-location-item-box-shadow'] :
                  defaultClassNameList
              const className = usingClassNameList.join(' ')
              return (
                <Button
                  className={className}
                  onClick={() => handlePickUpLocationClick(item, index)}
                  key={item.value}>
                  {item.label}
                </Button>
              )
            })
        }
      </div>
    )
  }

  const getQuotesRef = useRef(null)
  const googleMapsRef = useRef(null)

  const [isShowGetQuotes, setIsShowGetQuotes] = useState(false)
  const [dropOffLocation, setDropOffLocation] = useState(undefined)
  const [currentDropOffLocationData, setCurrentDropOffLocationData] = useState(undefined)
  const handleDropOffLocationChange = (value) => {
    setDropOffLocation(value)
    setCurrentDropOffLocationData(formatGMapsAddress(value))
  }
  const handleGetAnInstantCodeClick = () => {
    if (!dropOffLocation) {
      return message.warning(t('instant_dra_input'))
    }
    handleModalCancel()
    setIsShowGetQuotes(true)
  }
  const getAnInstantQuote = (getQuotesFormData) => {
    const { company, emailAddress, firstName, lastName, monthlyVolume, phoneNumber, commodity, weight } = getQuotesFormData || {}
    const { postcode, city, state } = currentDropOffLocationData || {}
    const params = {
      First_Name: firstName,
      Last_Name: lastName,
      Email_Address: emailAddress,
      Company: company,
      Phone_Number: phoneNumber,
      Monthly_Volume: monthlyVolume,
      zipcode: postcode,
      city: city,
      state: state,
      Commodity: commodity,
      Weight: weight,
      // 'address1': 'Los Angeles / Long Beach Port and Rail',
      // 'address2': 'Savannah Port and Rail',
      // 'address3': 'Houston Port and Rail',
      // 'address4': 'NJ/ NYC Port and Rail'
      pickup_address: currentPickUpLocation,
    }
    return getAnInstantQuoteByApi(params)
      .then(res => {
        const resDataData = res.data?.data || {}
        const {
          accessorial_charges,
          additional_charges,
          empty_return_policy,
          fixed_expresen,
          storage_fee,
        } = resDataData;
        const getJSONParseData = data => data ? JSON.parse(data) : []
        let data = {
          ...resDataData,
          accessorialCharges: getJSONParseData(accessorial_charges),
          additionalCharges: getJSONParseData(additional_charges),
          emptyReturnPolicy: getJSONParseData(empty_return_policy),
          fixedExpense: getJSONParseData(fixed_expresen),
          storageFee: getJSONParseData(storage_fee),
        }
        if (isEmpty(resDataData)) {
          data = {}
        }
        dispatch(getAnInstantQuoteSuccessAction(data))
        return true
      })
      .catch(() => false)
  }
  const hideGetQuotesModal = () => {
    setIsShowGetQuotes(false)
  }

  const [isShowGetQuotesSuccess, setIsShowGetQuotesSuccess] = useState(false)
  const [currentMonthlyVolumeVal, setCurrentMonthlyVolumeVal] = useState(GET_QUOTES_MONTHLY_VOLUME_1_VAL)
  const getQuotesSuccessType = useMemo(() => {
    if (currentMonthlyVolumeVal !== GET_QUOTES_MONTHLY_VOLUME_1_VAL) {
      return GET_QUOTES_SUCCESS_TYPE_2_KEY
    }
    if (!anInstantQuoteData || isEmpty(anInstantQuoteData)) {
      return GET_QUOTES_SUCCESS_TYPE_2_KEY
    }
    return GET_QUOTES_SUCCESS_TYPE_1_KEY
  }, [currentMonthlyVolumeVal, anInstantQuoteData])
  const handleGetQuotesSuccess = async values => {
    setCurrentMonthlyVolumeVal(values.monthlyVolume)
    const isNext = await getAnInstantQuote(values)
    if (!isNext) {
      return
    }
    hideGetQuotesModal()
    getQuotesRef.current?.resetFields()
    setIsShowGetQuotesSuccess(true)
  }
  const hideGetQuotesSuccessModal = () => {
    setIsShowGetQuotesSuccess(false)
  }
  const handleModalCancel = () => {
    options.onModalCancel()
    // setCurrentTransportType(defaultCurrentTransportType)
    // setDropOffLocation('')
    // setCurrentMonthlyVolumeVal(GET_QUOTES_MONTHLY_VOLUME_1_VAL)
  }

  const [dropOffOptions, setDropOffOptions] = useState([]);
  // {
  //   value: d.value,
  //     label: d.text,
  // }
  const renderSearchInput = () => {
    const handleSearch = (newValue) => {
      const googleMapsCurrent = googleMapsRef?.current?.wrapperRef?.current
      googleMapsCurrent?.getSuggestionsListByText(newValue, data => {
        setDropOffOptions(getDeDuplicationData(data));
      })
    };

    return (
      <div className={'get-an-instant-code-port-rail-drayage-right-search-container'}>
        <img
          className={'get-an-instant-code-port-rail-drayage-right-input-icon'}
          src={banner2CartImg}
          alt=""
        />
        <Select
          className='get-an-instant-code-port-rail-drayage-right-input-container'
          showSearch
          value={dropOffLocation}
          placeholder={t('instant_dra_zip')}
          style={props.style}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleDropOffLocationChange}
          notFoundContent={null}
          options={dropOffOptions}
        />
      </div>
    );
  }

  return (
    <div>
      <Modal
        open={options.isShow}
        footer={null}
        maskClosable={false}
        onCancel={handleModalCancel}
        className={rootClassName}>
        <div className={'get-an-instant-code-port-rail-drayage-container'}>
          <div className={'get-an-instant-code-port-rail-drayage-left-container'}>
            {/* <div className={'get-an-instant-code-port-rail-drayage-left-title'}>
              {t('instant_fba_quote')}
            </div>
            <div className={'get-an-instant-code-port-rail-drayage-left-des'}>
              {t('instant_dra_infomation')}
            </div>
            <div className={'get-an-instant-code-port-rail-drayage-left-tip'}>
              {t('instant_fba_thank')}
            </div> */}
            <div className={'get-an-instant-code-port-rail-drayage-left-img-container'}>
              <img src={banner2MapImg} alt="" />
            </div>
          </div>
          <div className='get-an-instant-code-port-rail-drayage-right-container'>
            <div className={'get-an-instant-code-port-rail-drayage-right-title'}>
              {t('instant_dra_trans')}
            </div>
            {renderTransportType()}
            <div className={'get-an-instant-code-port-rail-drayage-right-title'}>
              {t('instant_dra_pick')}
            </div>
            {renderPickUpLocation()}
            <div className={'get-an-instant-code-port-rail-drayage-right-title'}>
              {t('instant_dra_drop')}
            </div>
            {renderSearchInput()}
            <Button
              onClick={handleGetAnInstantCodeClick}
              className={'get-an-instant-code-port-rail-drayage-right-btn'}>
              {t('instant_quote')}
            </Button>
          </div>
        </div>
      </Modal>
      <GetQuotes
        ref={getQuotesRef}
        isShow={isShowGetQuotes}
        onModalCancel={hideGetQuotesModal}
        onGetQuotesSuccess={handleGetQuotesSuccess}
      />
      <GetQuotesSuccess
        isShow={isShowGetQuotesSuccess}
        onModalCancel={hideGetQuotesSuccessModal}
        type={getQuotesSuccessType}
      />
      <GoogleMaps ref={googleMapsRef} />
    </div>
  )
}
