const data = {
	"language_en": "English",
	"language_zh_cn": "中文(简体)",
	"language_zh_tw": "中文(繁体)",
	"home": "首页",
	"quote": "询价",
	"feedback": "反馈",
	"contact": "联系我们",
	"wechat_news": "关注我们",
	"wechat_contact": "微信联系",
	"wechat": "微信",
	"whats_app": "WhatsApp",
	"wechat_official_account": "微信公众号",
	"line_official_account": "LINE官方账号",
	"facebook": "脸书",
	"instagram": "Instagram",
	"linkedin": "领英",
	"redbook": "小红书",
	"line": "LINE",
	"estimate": "联系我们-免费估价",
	"wakool_service_area": "Wakool服务区域",
	"our_locations": "办事处地点",
	"send_us_message": "欢迎留言给我们",
	"leave_us_message": "欢迎留言给我们",
	"first_name": "名字",
	"last_name": "姓",
	"email_address": "邮箱",
	"company": "公司",
	"phone_n": "电话",
	"send_messae": "发送",
	"submit": "提交",
	"more_sales": "更高效的运输流程如何帮助您完成更多销售",
	"top_v": "俯视图",
	"logistic": "顶尖的现代物流运输服务",
	"freight": "Uber Freight 与 WattEV 进行里程碑式的电动卡车试点",
	"uber": "优步货运",
	"plans_deploy": "已宣布计划在南加州的选定路线上部署电动卡车。",
	"invoice_uber": "电动汽车此举将涉及优步货运庞大的数字运营商网络。该计划是与",
	"heavy_ev": "，重型电动汽车设计和制造的先驱，优步货运将使用其创新的电动车队和充电基础设施。",
	"first_shipper": "第一个参与试点的托运人将是",
	"global_leader": "，可持续物流领域的全球领导者。CHEP将开始在优步货运的南加州航线上运行电力。",
	"pillot_builds": "该试点建立在WattEV的使命之上，即开发一个全国性的重型充电设施网络，为电动卡车车队提供服务。",
	"chep_schedule": "CHEP将能够在一个地方预订、安排和完成装载，跟踪状态和装载KPI，并管理文书工作。",
	"blog_detail_addition": "除了零排放和无发动机噪音和烟雾的好处外，电动卡车的广泛采用将减少对不可再生燃料的依赖——目前价格不稳定——还将降低维护成本。",
	"blog_detail_milestone": "该试点是电动货运的一个里程碑，也是优步货运的首个电动汽车部署。",
	"blog_detail_electric": "电动卡车终于来了，我们很自豪能与WattEV合作，为优步货运托运人提供更多更可持续的货运方式，”优步货运可持续发展负责人表示，",
	"blog_detail_frankiv": "伊利娜·弗兰基夫",
	"blog_detail_profoundly": "电动卡车“将对物流产生深远影响”-优步货运",
	"blog_detail_added": "她补充道：“电动卡车将对物流产生深远影响，我们很高兴能够建立技术平台，使其能够无缝集成到供应链中。”",
	"blog_detail_says": "Frankiv表示，优步货运庞大的网络“在将电动汽车整合到托运人运输战略中的物流复杂性方面处于独特地位”。",
	"blog_detail_partnering": "她继续说道：“通过与托运人、电动汽车运营商和WattEV等充电网络提供商合作，我们是托运人和运营商将新兴技术融入其供应链的首选网络。”。",
	"marisa": "玛丽莎·桑切斯·乌雷亚",
	"blog_detail_Director": ", 集保母公司布兰堡全球供应链脱碳总监表示：“随着行业尝试可持续替代方案来补充传统产能，该试点项目将为利益相关者提供有关电气化货运物流复杂性的宝贵见解。",
	"blog_detail_Director2": "“美国的第一个电动卡车试点是朝着正确方向迈出的一步，因为我们正朝着净正碳影响的方向迈进。我们很自豪能与我们的运营商和客户合作，共同扩大电气化机会，实现我们共同的脱碳目标。”",
	"salim": "萨利姆·优素福扎德",
	"blog_detail_Director3": ",WattEV首席执行官表示：“我们很自豪能成为优步货运及其客户的首选零排放运输解决方案。",
	"blog_detail_Director4": "“结合我们的运输业务和卡车即服务模式，我们能够使用优步货运的数字平台，为托运人及其客户提供零排放卡车路线和服务。”",
	"top_v_ar": "热门浏览文章",
	"blog_tx1": "洛杉矶顶级现代物流服务",
	"message": "消息",
	"more_articles": "更多文章",
	"views_8": "888次浏览",
	"contact_quote1": "输入您的信息以查看报价",
	"contact_quote2": "请在下面填写您的信息以预订负载，我们的团队会尽快与您联系。",
	"contact_quote3": "请选择",
	"contact_quote4": "月度交易量",
	"contact_quote5": "点击“提交”，我同意我的信息将按照Wakool Transport的",
	"get_quotes": "提交",
	"cancel": "取消",
	"feedback_tx1": "我们非常重视您的反馈",
	"feedback_tx2": "为了持续提升用户体验，请让我们知道我们可以优化的地方！",
	"feedback_tx3": "有待改善的内容和建议",
	"feedback_tx4": "请输入告诉我们如何改进",
	"feedback_tx5": "发送",
	"feedback_tx6": "非常感谢您的反馈",
	"feedback_tx7": "我们正在努力为用户提供更好的体验。你在这个过程中帮助了我们！",
	"feedback_tx8": "请输入您的邮箱",
	"back_home": "返回主页",
	"footer_tx1": "威酷运输",
	"footer_tx2": "Truckxi技术支持",
	"arizona": "亚利桑那州",
	"georgia": "格鲁吉亚",
	"new_jersey": "新泽西州",
	"nevada": "内华达州",
	"california": "加利福尼亚州",
	"illinois": "伊利诺伊州",
	"new_york": "纽约",
	"texas": "德克萨斯州",
	"washington": "华盛顿",
	"virginia": "弗吉尼亚州",
	"maryland": "马里兰州",
	"footer_all_tx1": "您最佳的仓储、运输，物流伙伴",
	"footer_all_title": "运输监管识别号",
	"footer_all_mc": "MC 编号",
	"footer_all_dot": "DOT 编号",
	"footer_all_ca": "CA 编号",
	"footer_all_head": "总部",
	"footer_wakool_head": "Wakool总部",
	"footer_all_phone": "电话",
	"footer_all_email": "邮箱",
	"footer_all_address": "地址：加利福尼亚州罗兰高地圣何塞大道19130号，CA 91748",
	"footer_all_address_top": "地址：加利福尼亚州罗兰高地圣何塞大道19130号，",
	"footer_all_address_bottom": "CA 91748",
	"footer_all_taiwan": "台湾办事处",
	"footer_all_taiwan_branch": "台湾分公司",
	"footer_all_china_branch": "中国分公司",
	"footer_all_china_branch_desc_one": "中国上海市虹口区吴淞路575号吉汇大厦2523室",
	"footer_all_sec": "一段236号27楼",
	"footer_all_rd": "市政北二路，",
	"footer_all_xitun": "台湾台中西屯区",
	"footer_all_service": "服务区域",
	"footer_all_working": "工作时间",
	"footer_all_fri": "周一至周五：24小时全天候",
	"footer_all_sat": "周六：中午12点至下午4点（太平洋时间）",
	"footer_all_sunday": "周日：下午4点（太平洋时间）至次日中午12点",
	"footer_all_social": "社交媒体",
	"instant_port": "港口/提送",
	"instant_ware": "全美仓储",
	"instant_service": "服务",
	"instant_ltl": "散货运输",
	"instant_ftl": "全美长途运输",
	"instant_fba": "亚马逊FBA",
	"instant_walmart_label": "亚马逊/沃尔玛",
	"instant_walmart": "沃尔玛",
	"instant_quote": "快速询价",
	"instant_fba_quote": "快速询价",
	"instant_fba_please": "请点击",
	"instant_fba_contact": "“联系我们”",
	"instant_fba_information": "了解更多服务信息。无需注册。",
	"instant_fba_thank": "谢谢！",
	"instant_fba_desired": "选择您需要的服务",
	"amazon_FBA": "亚马逊FBA",
	"walmart_delivery": "沃尔玛运送",
	"select_service": "选择服务",
	"instant_fba_ocean": "海运柜直送",
	"instant_fba_trans": "转运全包（码头+仓库+派送）",
	"instant_fba_loose": "散货拼板",
	"instant_fba_back": "返回",
	"instant_fba_last_page": "返回上一页",
	"instant_warehouse_quote": "快速询价",
	"instant_warehouse_please": "请点击",
	"instant_warehouse_contact": "“联系我们”",
	"instant_warehouse_information": "了解更多服务信息。无需注册。",
	"instant_warehouse_thank": "谢谢！",
	"warehouse_location": "仓库地址",
	"select_location": "选择地址",
	"instant_warehouse_los_angeles": "洛杉矶",
	"instant_warehouse_new_jersey": "新泽西",
	"instant_warehouse_oak_land": "奥克兰",
	"instant_warehouse_seattle": "西雅图",
	"instant_warehouse_houston": "休斯敦",
	"instant_warehouse_baltimore": "巴尔的摩",
	"instant_warehouse_savannah": "萨凡纳",
	"instant_warehouse_chicago": "芝加哥",
	"instant_warehouse_back": "返回",
	"instant_otr_quote": "快速询价",
	"instant_otr_rates": "在您正式下单锁定价格之前，价钱可能会因市场变动而随时有所调整。",
	"instant_otr_from": "从",
	"instant_otr_to": "到",
	"instant_otr_pick": "提货日期",
	"instant_otr_drop": "到货日期",
	"instant_otr_back": "我要预订",
	"instant_dra_port": "港口",
	"instant_dra_rail": "铁路",
	"instant_dra_los": "洛杉矶/长滩",
	"instant_dra_sava": "萨凡纳",
	"instant_dra_nj": "新泽西/纽约",
	"instant_dra_hou": "休斯顿",
	"instant_dra_sea": "西雅图",
	"instant_dra_tacoma": "塔科马",
	"instant_dra_norfolk": "弗吉尼亚州诺福克",
	"instant_dra_baltim": "巴尔的摩",
	"instant_dra_char": "查尔斯顿",
	"instant_dra_tampa": "坦帕",
	"instant_dra_jack": "杰克逊维尔",
	"instant_dra_miami": "迈阿密",
	"instant_dra_mobile": "莫比尔",
	"instant_dra_boston": "波士顿",
	"instant_dra_oakland": "奥克兰",
	"instant_dra_vancouver": "加拿大温哥华",
	"instant_dra_toronto": "加拿大多伦多",
	"instant_dra_chicago": "芝加哥",
	"instant_dra_dallas": "达拉斯",
	"instant_dra_atlanta": "亚特兰大",
	"instant_dra_kansas": "堪萨斯",
	"instant_dra_zip": "邮政编码或城市名称",
	"instant_dra_infomation": "请填写以下信息，获取标准报价，无需注册。",
	"instant_dra_trans": "运输类型",
	"instant_dra_pick": "提货点",
	"instant_dra_drop": "卸货点",
	"instant_dra_input": "请输入邮政编码或城市名称",
	"instant_war_thank": "感谢您的关注",
	"instant_war_soon": "我们正在开发这项服务，很快就会推出",
	"quotes_see": "输入您的信息以获取报价并预订",
	"quotes_privacy": "隐私声明。",
	"quotes_volume": "请选择每月数量",
	"quotes_address": "请输入电子邮件地址",
	"quote_sc_pick": "提货点",
	"quote_sc_delivery": "送货点",
	"quote_sc_truck": "卡车",
	"quote_sc_included": "包含燃油费",
	"quote_sc_valid": "有效期自",
	"quote_sc_ctf": "CTF",
	"quote_sc_pier": "码头进出费",
	"quote_sc_terminal": "码头／铁路拥堵费",
	"quote_sc_nights": "预提费",
	"quote_sc_drop": "放柜费",
	"detention": "等候卸货费",
	"quote_sc_special": "特别说明",
	"quote_sc_round": "预计往返行程",
	"quote_sc_miles": "英里",
	"quote_sc_thanks": "感谢您提交",
	"quote_sc_member": "感谢您的关注，我们将尽快与您联系！",
	"home_los": "洛杉矶/长滩港",
	"home_sava": "萨凡纳港",
	"home_hous": "休斯顿港",
	"home_jersey": "新泽西港",
	"home_angeles": "洛杉矶铁路站",
	"home_rail": "达拉斯铁路站",
	"instant_otr_vehicle_type": "车辆类型",
	"instant_otr_vehicle_type_dry": "干柜",
	"instant_otr_vehicle_type_refrigerated": "冻柜",
	"instant_otr_vehicle_type_flatbed": "平板",
	"commodity": "货物",
	"weight": "重量",
	"monthly_volume": "每月货量",
	"reminder_note": "注意事项",
	"accessorial_charges": "附加费用",
	"additional_charges": "额外费用（若适用）",
	"storage_fee_policy": "场地费用政策",
	"empty_return_policy": "空柜返还政策",
	"additional_charge_desc": "可能产生的额外费用仅在费用产生时收取。"
}

export default data;
